import React, { useEffect, useState } from "react";
import PagingTable from "components/Table/Paging";
import { adminClient } from "apis/admin";
import Card from "components/Card";
import PageTitle from "components/Title/Page";
import { columnInfos } from "./config";
import ToggleSearchInput from "components/Form/Input/ToggleSearch";
import { randomString } from "utils/math";

const PointHistory = () => {
    const [filterParams, setFilterParams] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [refreshKey, setRefreshKey] = useState(randomString());

    useEffect(() => {
        console.log("[PointHistory] useEffect", searchText);
        setFilterParams({
            ...(searchText && { search_text: searchText })
        })
        setRefreshKey(randomString());
    }, [searchText])

    const fetchTableData = async (page: number, limit: number) => {
        console.log("[PointHistory] fetchTableData", page, limit);
        if(!filterParams) {
            return { data: [], page: 1, total: 0 }
        }
        try {
            const params = {
                page,
                limit,
                ...filterParams
            }
            const ret = await adminClient.get(`/users/point/history`, { params });
            console.log("[fetchTableData] ret", ret);
            if (!ret?.data?.data) {
                return { data: [], page: 1, total: 0 }
            }
            return {
                data: ret.data.data,
                page: ret.data.page,
                total: ret.data.total,
            }
        } catch (e) {
            console.error(e);
            return { data: [], page: 1, total: 0 }
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>포인트 내역 확인</PageTitle>
                <div className="flex mb-2">
                    <ToggleSearchInput
                        upperLabel="고객검색"
                        upButtonLabel="적용"
                        downButtonLabel="해제"
                        onToggle={(isUp, text) => { setSearchText(isUp ? null : text) }}
                        placeholder="고객명, 전화번호 검색"
                    />
                </div>
                <PagingTable
                    fetchData={fetchTableData}
                    columns={columnInfos}
                    windowSize={20}
                    refreshKey={refreshKey}
                />
            </div>
        </Card>
    )
}

export default PointHistory;
