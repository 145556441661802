import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import InputUpperLabel from 'components/Form/Input/Label/InputUpper';
import { CustomDatePickerInput } from '..';

// Set the first selectable year (e.g., 2000)
const MIN_YEAR = 2000;
const CURRENT_YEAR = new Date().getFullYear();

export const renderYearHeader = ({
    date,
    changeYear
}) => {
    return (
        <div className="flex justify-center items-center text-center">
            <button onClick={() => changeYear(date.getFullYear() - 1)}>
                <ChevronLeftIcon className="h-8 w-8" />
            </button>
            <div className="text-base font-semibold px-1 w-fit">
                <select
                    className="w-full p-0 border-none bg-transparent text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-sm"
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(Number(value))}
                    style={{ backgroundImage: "none" }}
                >
                    {Array.from({ length: CURRENT_YEAR - MIN_YEAR + 1 }, (_, i) => MIN_YEAR + i).map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            <button onClick={() => changeYear(date.getFullYear() + 1)}>
                <ChevronRightIcon className="h-8 w-8" />
            </button>
        </div>
    );
};

const YearWithStep = ({ upperLabel = "", onDtChanged = (dt: Date) => { }, ...otherProps }) => {
    const [selectedDt, setSelectedDt] = useState(new Date());

    const handleFirstButtonClick = () => {
        const firstDate = new Date(MIN_YEAR, 0, 1);
        setSelectedDt(firstDate);
    }

    const handlePreviousButtonClick = () => {
        const prevDate = new Date(selectedDt);
        prevDate.setFullYear(prevDate.getFullYear() - 1);
        setSelectedDt(prevDate);
    }

    const handleNextButtonClick = () => {
        const nextDate = new Date(selectedDt);
        nextDate.setFullYear(nextDate.getFullYear() + 1);
        if (nextDate.getFullYear() > CURRENT_YEAR) return;
        setSelectedDt(nextDate);
    }

    const handleLastButtonClick = () => {
        const lastDate = new Date(CURRENT_YEAR, 0, 1);
        setSelectedDt(lastDate);
    }

    useEffect(() => {
        onDtChanged(selectedDt);
    }, [selectedDt]);

    return (
        <div>
            <InputUpperLabel>
                {upperLabel}
            </InputUpperLabel>
            <div className="isolate inline-flex rounded-md shadow-sm z-20">
                <button
                    type="button"
                    className="relative -mr-px inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handleFirstButtonClick}
                >
                    <span className="sr-only">First Year</span>
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="relative -mr-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handlePreviousButtonClick}
                >
                    <span className="sr-only">Previous Year</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <DatePicker
                    dateFormat={"yyyy"}
                    selected={selectedDt}
                    onChange={dt => setSelectedDt(dt)}
                    customInput={<CustomDatePickerInput />}
                    showPopperArrow={false}
                    showYearPicker={true}
                    renderCustomHeader={renderYearHeader}
                    {...otherProps}
                />
                <button
                    type="button"
                    className="relative -ml-px inline-flex items-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handleNextButtonClick}
                >
                    <span className="sr-only">Next Year</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    onClick={handleLastButtonClick}
                >
                    <span className="sr-only">Last Year</span>
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
}

export default YearWithStep;
