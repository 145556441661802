import React, { useEffect, useState } from "react";
import axios from "axios";
import { CustomerState } from "pages/Admin/Customer/Status/config";
import SimpleTable from "components/Table/Simple";
import { columnInfos } from "./config";
import Card from "components/Card";
// import { memberClient } from "apis/member";
import { doctorClient } from "apis/doctor";
import PageTitle from "components/Title/Page";
import Select from "components/Form/Select";
import _, { set } from "lodash";

const DoctorPrescriptionPage = () => {
    const [tableData, setTableData] = useState([]);
    const [intakeItems, setIntakeItems] = useState(null);
    const [selectedIntakeItem, setSelectedIntakeItem] = useState(null);

    useEffect(() => {
        fetchIntakes();
    }, [])

    useEffect(() => {
        if (_.isNil(selectedIntakeItem)) {
            return;
        }
        fetchSubmissions();
    }, [selectedIntakeItem])

    const fetchIntakes = async () => {
        try {
            const ret = await doctorClient.get(`/public/intakes`);
            console.log("[fetchIntakes] ret", ret.data.data);
            const intakes = ret.data.data;
            if(_.isNil(ret.data.data)) {
                return;
            }
            const itmes = intakes.reverse().map((elm) => {
                return {
                    name: elm.title,
                    value: elm.intake_id
                }
            })
            console.log("[fetchIntakes] items", itmes);
            const tempIntakeItems = [
                { name: "모든 진료지", value: null },
                null,
                ...itmes
            ]
            setIntakeItems(tempIntakeItems);
            setSelectedIntakeItem(tempIntakeItems[0]);
        } catch (e) {
            console.error(e);
        }
    }

    const fetchSubmissions = async () => {
        console.log("[fetchSubmissions] selectedIntakeItem", selectedIntakeItem);
        const intakeId = selectedIntakeItem.value;
        try {
            const params = {
                ...(!_.isNil(intakeId) && { intake_id: intakeId }),
                states: "AWAITING_PRESCRIPTION",
                page: 1,
                limit: 1000
            }
            const ret = await doctorClient.get(`/public/trackings`, { params });
            const rawData = ret.data.data;
            const filteredData = rawData.filter((elm) => [CustomerState.AWAITING_PRESCRIPTION].includes(CustomerState[elm.state]));
            console.log(filteredData);
            setTableData(filteredData);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>처방전 작성</PageTitle>
                <div className="flex overflow-auto pb-2">
                    <div>
                        <Select items={intakeItems} selectedItem={selectedIntakeItem} onChange={setSelectedIntakeItem} upperLabel="진료지 제목" />
                    </div>
                </div>
                <SimpleTable data={tableData} columns={columnInfos}></SimpleTable>
            </div>
        </Card>
    )
}

export default DoctorPrescriptionPage;
