import React, { useEffect, useState } from 'react';
import { AdminClient, adminClient } from 'apis/admin';
import Card from 'components/Card';
import _, { set } from 'lodash';
import SimpleTable, { Column, RowInfo } from 'components/Table/Simple';
import { columnInfos, statColumnInfos } from './config';
import Button from 'components/Form/Button';
import IconButton from 'components/Form/Button/Icon';
import excelIcon from 'assets/logo/excel.png';
import PageTitle from 'components/Title/Page';
import DateWithStep from 'components/Form/Datepicker/DateWithStep';
import MonthWithStep from 'components/Form/Datepicker/MonthWithStep';
import YearWithStep from 'components/Form/Datepicker/YearWithStep';
import Select, { getUidOfItems } from 'components/Form/Select';
import { createDateArray } from 'utils/time';
import SpinnerMedium from 'components/Spinner/Medium';
import moment from 'moment-timezone';
import { convertClinicIdColumnSelectable, createRowInfoWithClinic } from 'pages/Admin/Clinic/helper';
import { formatProductName } from 'pages/Admin/Task/InputPurchaseInfo/Editor/helper';
import { toast } from 'react-toastify';
import PagingTable from 'components/Table/Paging';
import { randomString } from 'utils/math';
import ToggleSearchInput from 'components/Form/Input/ToggleSearch';
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import Input, { InputFormat } from 'components/Form/Input';
import { SimpleDatePicker } from 'components/Form/Datepicker';
import { useModal } from 'components/Modal/Simple';
import { formatDate } from 'components/Table/Simple/presets';
import { useNavigate } from 'react-router-dom';
import { useDateDialog } from 'components/Dialog/Date';
import { useInputDialog } from 'components/Dialog/Input';
import { confirmDelete } from 'utils/browser';

export const TERM_ITEMS = [
    { name: "전체", value: "ALL" },
    { name: "월", value: "MONTH" },
    { name: "일", value: "DAY" },
    // { name: "연", value: "YEAR" } //disable because of performance issue
]

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0); // 시간을 00:00:00으로 설정

// 종료 날짜 (2024-01-01)
const START = new Date("2024-01-01");

export const DTS = createDateArray(START, TODAY);

const RefundInfoForm = ({ externalData = null, resolveRef = null, closeModal = () => { } }) => {
    const [reason, setReason] = useState(externalData?.refund_reason);
    const [amount, setAmount] = useState(externalData?.refund_amount);
    const [refundDt, setRefundDt] = useState(externalData?.refund_date ?? null);

    const onSubmit = async () => {
        console.log("[RefundInfoForm] onSubmit", reason, amount, refundDt);
        // return { refund_reason: reason, refund_amount: amount, refund_date: refundDt };
        resolveRef.current({ refund_reason: reason, refund_amount: amount, refund_date: refundDt });
        closeModal();
    }

    const onCancel = async () => {
        console.log("[RefundInfoForm] onCancel");
        resolveRef.current({ refund_reason: null, refund_amount: null, refund_date: null });
        closeModal();
    }

    return (
        <div className="flex flex-col space-y-2">
            <div>
                <h1 className="font-semibold">
                    환불사유
                </h1>
                <Input
                    value={reason}
                    onChange={setReason}
                />
                <div className="text-xs">
                    환불 사유는 사용자에게 노출되지 않습니다.
                </div>
            </div>
            <div>
                <h1 className="font-semibold">
                    환불금액
                </h1>
                <Input
                    value={amount}
                    onChange={setAmount}
                    format={InputFormat.NUMBER}
                />
                <div className="text-xs">
                    환불 금액 비율에 맞게 포인트 조정이 필요하다면 <br></br>"회원정보 관리"에서 수정해주세요.
                </div>
            </div>
            <div>
                <h1 className="font-semibold">
                    환불일
                </h1>
                <SimpleDatePicker selectedDt={refundDt} onDtChanged={setRefundDt} />
            </div>
            <div className="pt-1 flex justify-between">
                <div>
                    <Button
                        onButtonClicked={onCancel}
                    >
                        환불취소
                    </Button>
                </div>
                <div>
                    <Button
                        onButtonClicked={onSubmit}
                    >
                        환불처리
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useRefundDialog = () => {
    const { Modal, openModalSync } = useModal();

    const openDialog = async (row): Promise<any> => {
        return await openModalSync(row);
    }

    return {
        DialogProvider: () =>
            <Modal>
                <RefundInfoForm />
            </Modal>,
        openDialog
    }
}

const PurchaseStatViewer = () => {
    const [tableData, setTableData] = useState(null);
    const [clinicInfos, setClinicInfos] = useState(null);
    const [clinicItems, setClinicItems] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [summaryTableData, setSummaryTableData] = useState(null);

    useEffect(() => {
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
            const tempClinicItems = infos.map((info) => {
                return {
                    name: info.name,
                    data: info.clinic_id
                }
            })
            console.log("[PurchaseStatViewer] tempClinicItems", tempClinicItems);
            setClinicItems(tempClinicItems);
            setSelectedClinicItem(tempClinicItems[0]);
        });
    }, [])

    useEffect(() => {
        if (_.isNil(selectedClinicItem)) {
            return;
        }
        if (_.isNil(selectedMonth)) {
            return;
        }
        fetchData();
    }, [selectedClinicItem, selectedMonth])

    const fetchData = async () => {
        try {
            const clinic_id = selectedClinicItem.data;
            const selectedDt = new Date(selectedMonth);
            console.log("[fetchData] clinic_id, selectedDt", clinic_id, selectedDt);
            const params = {
                clinic_id,
                startDate: moment(selectedDt).startOf('month').format("YYYY-MM-DD"),
                endDate: moment(selectedDt).endOf('month').format("YYYY-MM-DD")
            }
            const ret = await adminClient.get('/purchases/statistics/monthly', { params });
            console.log("[fetchData] ret", ret);
            setTableData(ret.data);
            let total: any = {};
            ret.data.forEach((data) => {
                total = {
                    daily_total_purchase: (total.daily_total_purchase ?? 0) + data.daily_total_purchase,
                    daily_total_refund: (total.daily_total_refund ?? 0) + data.daily_total_refund,
                    daily_total_used_point: (total.daily_total_used_point ?? 0) + data.daily_total_used_point,
                    daily_total_earned_point: (total.daily_total_earned_point ?? 0) + data.daily_total_earned_point,
                }
            })
            console.log("!!! TOTAL", total);
            setSummaryTableData([total]);
        } catch (e) {
            console.error("[PurchaseStatViewer] e", e);
        }
    }

    // if (_.isNil(tableData)) {
    //     return <SpinnerMedium />
    // }

    return (
        <div>
            <PageTitle>매출 통계</PageTitle>
            <div className="flex space-x-2 pb-2 overflow-auto">
                <div>
                    <Select items={clinicItems} selectedItem={selectedClinicItem} onChange={setSelectedClinicItem} />
                </div>
                <div className="z-40">
                    <MonthWithStep onDtChanged={setSelectedMonth} />
                </div>
            </div>
            <h1 className="text-sm font-semibold">
                월 합계
            </h1>
            <SimpleTable data={summaryTableData} columns={statColumnInfos.filter((info) => ["daily_total_purchase", "daily_total_refund", "daily_total_used_point", "daily_total_earned_point"].includes(info.field))} />
            <h1 className="text-sm font-semibold">
                일별 데이터
            </h1>
            <SimpleTable data={tableData} columns={statColumnInfos} />
        </div>
    )
}

const PurchaseInfoPage = () => {
    const navigate = useNavigate();
    const [selectedTermItem, setSelectedTermItem] = useState(null);
    const [filterParams, setFilterParams] = useState(null);
    const [selectedDt, setSelectedDt] = useState(null);
    const [clinicInfos, setClinicInfos] = useState([]);
    const [productInfos, setProductInfos] = useState([]);
    const [clinicItems, setClinicItems] = useState(null);
    const [selectedClinicItem, setSelectedClinicItem] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [refreshKey, setRefreshKey] = useState(randomString());
    const { DialogProvider, openDialog } = useRefundDialog();
    const { Modal: StatModal, openModal: openStatModal, closeModal: closeStatModal } = useModal();
    const { DialogProvider: DateDialogProvider, openDialog: openDateDialog } = useDateDialog();
    const { DialogProvider: InputDialogProvider, openDialog: openInputDialog } = useInputDialog();

    useEffect(() => {
        const uid = getUidOfItems(TERM_ITEMS);
        const initialTermItem = localStorage.getItem(uid);
        if (_.isNil(initialTermItem)) {
            setSelectedTermItem(TERM_ITEMS[0])
        }
        AdminClient.getClinicInfos().then((infos) => {
            setClinicInfos(infos);
        });
        fetchProductInfos();
    }, [])

    useEffect(() => {
        if (_.isEmpty(clinicInfos)) {
            return;
        }
        const items = clinicInfos.map((info) => {
            return {
                name: info.name,
                value: info.clinic_id
            }
        })
        const itesmWithAll = [{ name: "모든 의원", value: null }, null, ...items];
        setClinicItems(itesmWithAll);
        const uid = getUidOfItems(itesmWithAll);
        const initialClinicItem = localStorage.getItem(uid);
        if (_.isNil(initialClinicItem)) {
            setSelectedClinicItem(itesmWithAll[0]);
        }
    }, [clinicInfos])

    useEffect(() => {
        console.log("[useEffect] selectedTermItem, selectedDt, selectedClinicItem", selectedTermItem, selectedDt, selectedClinicItem)
        if (_.isNil(selectedTermItem)) {
            return;
        }
        if (_.isNil(selectedClinicItem)) {
            return;
        }
        let startDt = null;
        let endDt = null;
        if (selectedTermItem.value === "DAY") {
            if (_.isNil(selectedDt)) {
                return;
            }
            startDt = new Date(selectedDt);
            endDt = new Date(selectedDt);
        }
        if (selectedTermItem.value === "MONTH") {
            if (_.isNil(selectedDt)) {
                return;
            }
            startDt = new Date(selectedDt);
            startDt.setDate(1);
            endDt = new Date(startDt);
            endDt.setMonth(endDt.getMonth() + 1);
            endDt.setDate(endDt.getDate() - 1);
        }
        if (selectedTermItem.value === "YEAR") {
            if (_.isNil(selectedDt)) {
                return;
            }
            startDt = new Date(selectedDt);
            startDt.setMonth(0);
            startDt.setDate(1);
            endDt = new Date(selectedDt);
            endDt.setMonth(11);
            endDt.setDate(31);
        }
        setFilterParams({
            ...(!_.isNil(startDt) && { startDate: moment(startDt).format("YYYY-MM-DD") }),
            ...(!_.isNil(endDt) && { endDate: moment(endDt).format("YYYY-MM-DD") }),
            ...(!_.isNil(selectedClinicItem.value) && { clinic_id: selectedClinicItem.value }),
            ...(!_.isNil(searchText) && { search_text: searchText })
        });
    }, [selectedTermItem, selectedDt, selectedClinicItem, searchText])

    useEffect(() => {
        if (_.isNil(filterParams)) {
            return;
        }
        // updateTableData();
        setRefreshKey(randomString());
    }, [filterParams])

    const fetchTableData = async (page, limit) => {
        console.log("[fetchTableData] page, limit, filterParams", page, limit, filterParams);
        if(_.isNil(filterParams)) {
            return { data: [], page: 1, total: 0 }
        }
        try {
            const params = {
                page,
                limit,
                ...filterParams
            }
            const ret = await adminClient.get(`/purchases`, { params });
            console.log("[fetchTableData] ret", ret);
            if (_.isNil(ret?.data?.data)) {
                return { data: [], page: 1, total: 0 }
            }
            const tempTableData = ret.data.data;
            console.log("[fetchTableData] tempTableData", tempTableData);
            return {
                data: tempTableData,
                page: ret.data.page,
                total: ret.data.total
            }
        } catch (e) {
            console.error("[fetchTableData] e", e);
            return { data: [], page: 1, total: 0 }
        }
    }

    const fetchProductInfos = async () => {
        try {
            const params = {
                page: 1,
                limit: 100000
            }
            const ret = await adminClient.get(`/products`, { params });
            console.log("[fetchProductInfos] ret", ret);
            if (_.isNil(ret?.data?.data)) return console.error("No data");
            const tempProductInfos = ret.data.data;
            console.log("[fetchProductInfos] tempProductInfos", tempProductInfos);
            setProductInfos(tempProductInfos);
        } catch (e) {

        }
    }

    const onDtChanged = (dt) => {
        console.log("[onDtChanged] term, dt", selectedTermItem.value, dt);
        setSelectedDt(dt);
    }

    const onRefundButtonClicked = async (row) => {
        const result = await openDialog(row);
        console.log("[onRefundButtonClicked] result", result);
        if (_.isNil(result)) {
            return;
        }
        try {
            //put /purchases/{id}
            const data = {
                refund_reason: result.refund_reason,
                refund_amount: Number(result.refund_amount),
                refund_date: result.refund_date
            }
            const ret = await adminClient.put(`/purchases/${row.submission_id}`, data);
            console.log("[onRefundButtonClicked] ret", ret);
            toast.success("환불정보가 수정 되었습니다.");
            setRefreshKey(randomString());
        } catch (e) {
            console.error(e);
            toast.error("환불정보 수정에 실패했습니다.");
        }
    }

    const columnInfosWithProduct: Column[] = columnInfos.map((columnInfo) => {
        if (columnInfo.field === "modify") {
            return {
                ...columnInfo,
                render: (value, field, row) => {
                    return (
                        <IconButton
                            paddingClassName="px-2 py-1"
                            onButtonClicked={() => {
                                navigate(`/admin/task/input-purchase-info/editor/${row.submission_id}`)
                            }}
                        >
                            <div className="text-xs">
                                구매정보 수정
                            </div>
                        </IconButton>
                    )
                },
            }
        }
        if (columnInfo.field === "delete") {
            return {
                ...columnInfo,
                render: (value, field, row) => {
                    return (
                        <IconButton
                            paddingClassName="px-2 py-1"
                            onButtonClicked={async () => {
                                // const ok = window.confirm("구매정보를 삭제하시겠습니까?");
                                const ok = confirmDelete("구매정보를 삭제하시겠습니까?");
                                if (!ok) {
                                    return;
                                }
                                try {
                                    const ret = await adminClient.delete(`/purchases/${row.submission_id}`);
                                    console.log("[delete ret]", ret);
                                    toast.success("구매정보가 삭제 되었습니다.");
                                    setRefreshKey(randomString());
                                } catch (e) {
                                    console.error(e);
                                    toast.error("구매정보 삭제에 실패했습니다.");
                                }
                            }}
                        >
                            <div className="text-xs">
                                구매정보 삭제
                            </div>
                        </IconButton>
                    )
                },
            }
        }
        if (columnInfo.field === "purchase_at") {
            return {
                ...columnInfo,
                render: (value, field, row) => {
                    return (
                        <div className="cursor-pointer flex space-x-1.5 items-center" onClick={async () => {
                            // onRefundButtonClicked(row);
                            const result = await openDateDialog("구매일 수정", row.purchase_at);
                            if (_.isNil(result)) {
                                return;
                            }
                            console.log("[purchase_at] result", result);
                            try {
                                const ret = await adminClient.put(`/purchases/${row.submission_id}`, { purchase_at: result });
                                console.log("[purchase_at] ret", ret);
                                toast.success("구매일이 수정 되었습니다.");
                                setRefreshKey(randomString());
                            } catch (e) {
                                console.error(e);
                                toast.error("구매일 수정에 실패했습니다.");
                            }
                        }}>
                            <div>
                                <PencilSquareIcon className="size-4" />
                            </div>
                            <div>
                                {formatDate(value)}
                            </div>
                        </div>
                    )
                }
            }
        }
        if (["clinic_user_id", "user_name", "assigned"].includes(columnInfo.field)) {
            return {
                ...columnInfo,
                render: (value, field, row) => {
                    return (
                        <div className="cursor-pointer flex space-x-1.5 items-center" onClick={async () => {
                            const fieldLabel = columnInfos?.find((info) => info.field === field)?.label;
                            const result = await openInputDialog(`${fieldLabel} 수정`, value);
                            if (_.isNil(result)) {
                                return;
                            }
                            console.log("[purchase_at] result", result);
                            try {
                                const ret = await adminClient.put(`/purchases/${row.submission_id}`, { [field]: result });
                                console.log("[purchase_at] ret", ret);
                                toast.success("수정 되었습니다.");
                                setRefreshKey(randomString());
                            } catch (e) {
                                console.error(e);
                                toast.error("수정에 실패했습니다.");
                            }
                        }}>
                            <div>
                                <PencilSquareIcon className="size-4" />
                            </div>
                            <div>
                                {value}
                            </div>
                        </div>
                    )
                }
            }
        }
        if (columnInfo.field === "items") {
            return {
                ...columnInfo,
                render: (value, field, row) => {
                    const items = row.items;
                    const productNames = []
                    items.forEach((item) => {
                        if (!_.isNil(item.name)) {
                            productNames.push(item.name);
                            return;
                        }
                        const product = productInfos.find((productInfo) => productInfo.product_id === item.product_id);
                        if (_.isNil(product)) {
                            return;
                        }
                        const label = formatProductName(product);
                        productNames.push(label);
                    });
                    return productNames.join(", ");
                }

            }
        }
        if (columnInfo.field === "refund") {
            return {
                ...columnInfo,
                render: (value, field, row) => {
                    return (
                        <div className="cursor-pointer flex space-x-1.5 items-center" onClick={() => {
                            onRefundButtonClicked(row);
                        }}>
                            <div>
                                <PencilSquareIcon className="size-4" />
                            </div>
                            <div>
                                {formatDate(row.refund_date)}
                            </div>
                        </div>
                    )
                },
            }
        }
        return columnInfo
    })


    const columnInfosWithClinic = convertClinicIdColumnSelectable(columnInfosWithProduct, clinicInfos);
    const rowInfoWithClinic: RowInfo = createRowInfoWithClinic(clinicInfos);

    return (
        <Card>
            <div className="p-3">
                <PageTitle>구매정보 확인</PageTitle>
                <div className="flex justify-between items-end pb-2 space-x-2 overflow-auto">
                    <div className="flex space-x-2 z-50">
                        <div>
                            <Select upperLabel="의원" items={clinicItems} selectedItem={selectedClinicItem} onChange={setSelectedClinicItem} saveSelectedItems={true} />
                        </div>
                        <div>
                            <Select upperLabel="기간 유형" items={TERM_ITEMS} selectedItem={selectedTermItem} onChange={setSelectedTermItem} saveSelectedItems={true} />
                        </div>
                        {_.isNil(selectedTermItem) ? <SpinnerMedium /> :
                            <div hidden={selectedTermItem.value === "ALL"}>
                                {selectedTermItem.value === "DAY" && <DateWithStep upperLabel="구매일" dts={DTS} onDtChanged={onDtChanged} />}
                                {selectedTermItem.value === "MONTH" && <MonthWithStep upperLabel="구매월" onDtChanged={onDtChanged} />}
                                {selectedTermItem.value === "YEAR" && <YearWithStep upperLabel="구매년도" onDtChanged={onDtChanged} />}
                            </div>
                        }
                        <div className="min-w-36">
                            <ToggleSearchInput
                                upperLabel="고객검색"
                                upButtonLabel="적용"
                                downButtonLabel="해제"
                                onToggle={(isUp, value) => { setSearchText(!isUp ? value : null) }}
                                placeholder="고객명, 고객번호, ID 검색"
                            />
                        </div>
                    </div>

                    <div className="flex space-x-2">
                        <div>
                            <Button onButtonClicked={() => { openStatModal() }}>
                                매출 통계
                            </Button>
                        </div>
                        <div>
                            <IconButton onButtonClicked={async () => {
                                console.log("[PurchaseInfoPage] download xlsx")
                                if (_.isNil(filterParams.startDate) || _.isNil(filterParams.endDate)) {
                                    toast.info("전체 조회 기간은 기본 1달로 설정됩니다.");
                                }
                                AdminClient.downloadXlsx("/purchases/download/xlsx", {
                                    startDate: moment(new Date()).subtract(1, "month").format("YYYY-MM-DD"),
                                    endDate: moment(new Date()).format("YYYY-MM-DD"),
                                    ...filterParams
                                }, "downloaded_purchases.xlsx");
                            }}>
                                <div className="size-5">
                                    <img src={excelIcon} alt="excel" />
                                </div>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <PagingTable
                    fetchData={fetchTableData}
                    columns={columnInfosWithClinic}
                    rowInfo={rowInfoWithClinic}
                    refreshKey={refreshKey}
                    windowSize={20}
                />
            </div>
            <DialogProvider />
            <StatModal>
                <PurchaseStatViewer />
            </StatModal>
            <DateDialogProvider />
            <InputDialogProvider />
        </Card>
    )
}

export default PurchaseInfoPage;